
.propertyGridTable {
    padding-top: 5px;
  }
  
  .propertyRow .controlRow {
    display: flex;
    align-items: flex-start;
  }
  
  .formRow .controlRow {
    display: flex;
    align-items: flex-start;
  }
  
  
  .formLabel {
    flex: 1;
    font-weight: bold;
    width: 100%;
    word-break: normal;
    padding-left: 5px;
  }
  
  .formValue {
    flex: 1;
    display: flex;
    width: 100%;
    word-break: normal;
    padding-right: 5px;
  }
  
  .control-label {
    word-break: normal;
  }
  
  /* color rules */
  .info {
      color: #286090;
  }
  
  .danger {
      color: #C9302C;
  }
  
  .warning {
      color: #EC971F;
  }
  
  .success {
      color: #449D44;
  }
  
  
  .inputDialogHelpMessageArea {
    padding: 4px;
    font-weight: bold;
    text-align: center;
    min-height: 0px;
  }
  
  .pushItemsToBottom {
    align-items: flex-end;
  }

