#contentColumn > ciffed {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
  }

  .wrapper {
    font-size: medium;
  }
  
  .mainPage .tabGridContentOverlay {
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .splash {
    margin: 0;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    height: 100%;
    width: 100%;
    background: #ffffff;
    opacity: 1;
  }
  
  .splash .fa-spinner {
    margin: 10% auto;
  }
  
  /* Begin: overridden bootstrap styles*/
  .editor-screen {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    height: 100vh;
  }
  
  .editor-screen__workspace {
    width: 100%;
  }
  
  .editor-screen__sidebar {
    width: 60px;
  }
  
  .workspace__menubar {
    width: 100%;
    height: 50px;
    left: 0;
    margin: 0;
    padding: 0;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 0;
    color: white;
  }
  
  .workspace__panning-indicator {
    position: absolute;
    top: 5%;
    left: 45%;
    background: white;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #455f75;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  
  .workspace__panning-indicator p {
    margin: 0 10px 0 0;
  }
  
  .workspace__panning-indicator i {
    font-size: 25px;
    position: relative;
    top: 2px;
  }
  
  /* Base Layer */
  .workspace__grid {
    width: 100%;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 0;
    background-color: #eee;
  }
  
  .grid__ruler-butt {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: gray;
  }
  
  .grid__main-ciff {
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
  }
  
  .main-ciff__top-extra-container {
    box-sizing: border-box;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  
  .top-extra-container__extra-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: calc(100vh - 45px);
    box-sizing: border-box;
    -ms-overflow-style: auto;
  }
  
  .extra-container__inner-container {
    margin-top: 20px;
    margin-left: 20px;
    -webkit-box-flex: 5;
    -ms-flex: 5 0 auto;
    flex: 5 0 auto;
    position: relative;
  }
  
  /* Layer 1 - Ciff container and properties grid */
  .inner-container__ciff-container {
    position: absolute;
    left: 0px;
    top: 0px;
    height: calc(100% - 11px)!important;
    width: calc(100% - 20px);
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 1;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  
  /* layer 2 - front most layer and the actual container holding the mxGraph instance */
  .ciff-container__document {
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow-y: auto;
    overflow-x: auto;
    z-index: 2;
  }
  
  .main-ciff__prop-handler-container {
    /* 12em is the width of the sidebars */
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 410px;
    -ms-flex: 0 0 410px;
    -o-flex: 0 0 410px;
    flex: 0 0 410px;
    position: relative;
    -ms-flex-preferred-size: 0px;
    flex-basis: 0px;
    box-sizing: border-box;
    /*width: 21px;*/
  }
  
  .main-ciff__props-grid-container--hidden,
  .main-ciff__props-grid-container--show {
    -webkit-transition: margin 0.3s ease;
    -ms-transition: margin 0.3s ease;
    -o-transition: margin 0.3s ease;
    transition: margin 0.3s ease;
    -ms-flex-preferred-size: 0px;
  }
  
  .main-ciff__props-grid-container--hidden {
    margin-right: -410px;
  }
  
  .main-ciff__props-grid-container--show {
    margin-right: 0px;
  }
  
  .prop-handler-container__bar {
    width: 21px;
    height: calc(100% - 20px);
    z-index: 101;
    position: absolute;
    margin-top: 20px;
    margin-left: 0px;
    right: -1px;
    line-height: 100px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    -webkit-transition: right 0.3s ease-in-out;
    transition: right 0.3s ease-in-out;
    cursor: pointer;
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: center;
    align-items: center;
    background: #455f75;
  }
  
  .bar__trigger-btn {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    .main-ciff__props-grid-container {
      -webkit-flex: 0 0 170px;
      -ms-flex: 0 0 170px;
      -o-flex: 0 0 170px;
      flex: 0 0 170px;
      background-color: #8dd0f2;
    }
  
    .main-ciff__props-grid-container--hidden {
      margin-right: -172px;
    }
  
    .main-ciff__props-grid-container--show {
      margin-right: 240px;
    }
  }
  
  .optimised-for-non-line > svg > g > g > g > image {
    -ms-interpolation-mode: bicubic; /* IE 7+ (non-standard property) */
    image-rendering: auto; /* Chrome 41+ and Opera 26+ */
  }
  
  .optimised-for-line > svg > g > g > g > image {
    -ms-interpolation-mode: nearest-neighbor; /* IE 7+ (non-standard property) */
    image-rendering: pixelated; /* Chrome 41+ and Opera 26+ */
  }
  