@media (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}

.custom-image {
    max-width: 100%;
    height: auto;
}

/* LANGUAGE SUPPORT */
.language-rtl {
    direction: rtl;
}
.reset-direction {
    direction: initial;
}
